/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
/*ion-toolbar {
    // --background: #3D4296;
   // --background: #004000;
    --background: #29526d;
     color: #fff !important;
    // --border-color: 0;
      // height: 50px;
      ion-back-button{
          color: #fff;
      }
   }*/
.md ion-segment {
  width: 60%;
  margin: auto;
}

ion-header ion-toolbar {
  --background: var(--ion-color-secondary);
}
ion-header ion-toolbar ion-segment {
  --background: var(--ion-color-primary);
}
ion-header ion-toolbar ion-segment ion-segment-button {
  --color: var(--ion-color-white);
  --color-checked: var(--ion-color-primary);
}

ion-content ion-list {
  margin-top: 2rem;
}
ion-content p ion-text {
  color: #000;
}

.flex {
  display: flex;
}

.full-width {
  width: 100% !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.text-tiny {
  font-size: 11px !important;
}

.text-small {
  font-size: 12px !important;
}

.text-medium {
  font-size: 14px !important;
}

.text-normal {
  font-size: 16px !important;
}

.text-large {
  font-size: 24px !important;
}

.text-xlarge {
  font-size: 36px !important;
}

.text-xxlarge {
  font-size: 48px !important;
}

.radius {
  border-radius: 8px !important;
}

.divider {
  background: var(--ion-color-light);
  margin: 8px 0;
  height: 1px;
  width: 100%;
}

.bold {
  font-weight: bold;
}

.swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.33);
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: var(--ion-color-white) !important;
  width: 40px;
  border-radius: 4px;
}

ion-list.transactions {
  border-radius: 8vh 8vh 0 0;
  background: var(--ion-color-secondary);
  padding-bottom: 10vh;
}
ion-list.transactions ion-list-header {
  padding-inline-start: 3.5vh;
}
ion-list.transactions ion-list-header ion-label, ion-list.transactions ion-item ion-label {
  font-size: 1.1rem;
}
ion-list.transactions ion-item-group {
  padding: 0 3.5vh;
}
ion-list.transactions ion-item-group ion-item {
  font-weight: bold;
  --background: transparent;
  --border-style: dashed;
  --border-color: var(--ion-color-tertiary);
  --padding-bottom: 1rem;
  --padding-top: 1rem;
  --padding-start: 0;
}
ion-list.transactions ion-item-group ion-item ion-text[slot=end] {
  font-size: 1rem;
}
ion-list.transactions ion-item-group ion-item p {
  font-size: 0.9rem;
  font-weight: normal;
  margin-top: 3px;
}
ion-list.transactions ion-item-group ion-item ion-thumbnail {
  height: 6vh;
  width: 6vh;
  border-radius: 10px;
  background: var(--ion-color-white);
  box-shadow: 0px 3px 6px rgba(3, 181, 170, 0.04);
}
ion-list.transactions ion-item-group ion-item ion-thumbnail img {
  height: 4vh;
  width: 4vh;
  transform: translateY(25%);
}

ion-popover .popover-viewport {
  display: contents;
}

.action-button {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.action-button ion-button {
  text-transform: capitalize;
  font-weight: 300;
  --background: #106eac;
  --border-radius: 7px;
  --box-shadow: none;
}
.action-button ion-icon {
  margin-right: 1px;
  color: #fff;
}

.top-menu {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}